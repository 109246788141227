import gql from 'graphql-tag';

const getTypesIdentification = gql`
  {
    types: typeIdentification {
      list: nodes
    }
  }
`;

const getParameters = gql`
  {
    countries(orderBy:NAME_ASC) {
      list: nodes {
        id
        name
        code
        cities(orderBy:NAME_ASC) {
          list: nodes {
            id
            name
          }
        }
        typeDocuments(orderBy: NAME_ASC){
          list: nodes {
            id
            name
          }
        }
      }
    }
  }
`;

const getDataUserxEmail = gql`
query {
  userByEmail(email: "info@modelosyprotocolo.co") {
    id
    address
    name
    password
  }
}
`;

const getUserLogin = gql`
  query ($email: String, $password: String){
    users(
      filter: {
        email: { includesInsensitive: $email }
        password: { equalTo: $password }
      }
    ){
      list: nodes {
        id
        name
        lastname
        state
        phone
        roleId
        agencies{
          list: nodes{
            id
            name
            state
            logo
            legalNature
            payment
          }
        }
      }
    }
  }
`;

const getAdminLogin = gql`
  query ($email: String, $password: String){
    userAdmins(
      filter: {
        email: { includesInsensitive: $email }
        password: { equalTo: $password }
      }
    ){
      list: nodes {
        id
        name
        lastname
        state
        phone
        roleId
      }
    }
  }
`;

const getEmailInActivateAccount = gql`
  query($id: UUID!) {
    user(id: $id) {
      id
      name
      email
      agencies {
        list: nodes {
          id
        }
      }
    }
  }
`;

const getEmailForgotPassword = gql`
  query ($email: String ){
    users( condition: { email: $email }) {
      list: nodes{
        id
        name
        lastname
      }
    }
  }
`;

const getEmailForgotPasswordTalent = gql`
  query ($email: String ){
    talents( condition: { email: $email }) {
      list: nodes{
        id
        name
        lastname
      }
    }
  }
`;

const GET_EVENTS_CLIENTS_BY_AGENCY = gql`
  query($id: UUID!) {
    agency(id: $id) {
      id
      name
      quotations{
        nodes {
          events{
            list: nodes{
              id
              name
              logo
              stateId
              startDate
              endDate
              quotation{
                code
              }
              eventDirectory {
                places: placeDirectory{
                  id
                  name
                  address
                  logo
                  city{
                    id
                    name
                    country{
                      id
                      name
                    }
                  }            
                }                  
              }              
            }
          }
        }
      }
    }
  }
`


const getStatesAgencyUser = gql`
  {
    statesAgency: stateItalentts(
      filter: { name: { includesInsensitive: "agen" } }
    ) {
      list: nodes {
        id
        name
        description
      }
    }

    statesUser: stateItalentts(
      filter: { name: { includesInsensitive: "usu" } }
    ) {
      list: nodes {
        id
        name
        description
      }
    }
  }
`
const getProfilesTalent = gql`
  {
    profileTalent: profileUserTalents {
      list: nodes {
        profile
        id
      }
    }
  }
`

const getStatesAll = gql`
  {
    profileTalent: profileUserTalents {
      list: nodes {
        profile
        id
      }
    }

    statesAgency: stateItalentts(
      filter: { name: { includesInsensitive: "agen" } }
    ) {
      list: nodes {
        id
        name
        description
      }
    }

    statesUser: stateItalentts(filter: { name: { includesInsensitive: "usu" } }) {
      list: nodes {
        id
        name
        description
      }
    }

    statesEvent: stateItalentts(
      filter: { name: { includes: "Eve" } }
    ) {
      list: nodes {
        id
        name
        description
      }
    }

    statesClient: stateItalentts(
      filter: {
        name: { includesInsensitive: "cli" }
        and: { id: { distinctFrom: 32 } }
      }
    ) {
      list: nodes {
        id
        name
        description
      }
    }

    statesAnnouncement: stateItalentts(filter: {
      name: { startsWith: "Convoca" }
    }){
      list: nodes{
        id
        name
        description
      }
    }

    statesTalents: stateItalentts(
      filter: { name: { startsWith: "Talento" } }
    ) {
      list: nodes {
        id
        name
        description
      }
    }

    statesQuotes: stateItalentts(
      filter: { name: { startsWith: "Cotización" } }
    ){
      list: nodes{
        id
        name
        description
      }
    }
  }
`
const GET_CLIENTS_BY_AGENCY = gql`
  query($id: UUID!) {
    agency(id: $id) {
      id
      name
      clients {
        list: nodes {
          id
          name
          address
          phone
          logo
          typeIdentificationId
          identification
          state
          url
          nameProducer
          phoneProducer
          emailProducer
          services{
            list: nodes{
              id
              name
            }
          }
          city {
            id
            name
            country {
              id
              name
            }
          }
        }
      }
    }
  }
`

const getServices = gql`
  {
    services{
      list: nodes{
        id
        name
        code
      }
    }
  }
`

const GETAGENCYBYUSER = gql`
  query($id: UUID!) {
    user(id: $id) {
      id
      name
      agencies {
        list: nodes {
          id
          name
        }
      }
    }
  }
`;

//Listado Busqueda Eventos
const GET_EVENTS_BY_AGENCY = gql`
  query($id: UUID!) {
    agency(id: $id) {
      id
      name
      quotations{
        nodes{
          events {
            list: nodes {
              id
              logo
              name
              startDate
              endDate
              state: stateId
              quotation{
                code
                createdAt
              }
              eventDirectory {
                places: placeDirectory{
                  id
                  name
                  address
                  logo
                  city{
                    id
                    name
                    country{
                      id
                      name
                    }
                  }            
                }                  
              }
            }
          }
        }
      }
    }
  }
`;

const GET_CLIENTS_BY_AGENCY_SELECT = gql`
  query($id: UUID!) {
    agency(id: $id) {
      id
      name
      clients(filter: {
        state: {
          distinctFrom: 53
        }
      }){
        list: nodes {
          id
          name
          state
          logo
          
        }
      }
    }
  }
`;

const GET_EVENTS_DIRECTORY = (date) => {
  let cadena = `
    {
      eventDirectories (filter: {startDate: {greaterThan: "${date}"}}){
        list: nodes {
          id
          name
          startDate
          endDate
          description
          logo
          placeDirectory {
            id
            name
            address
            phone
            logo
            city {
              id
              name
              country {
                id
                name
              }
            }
          }
        }
      }
    }
  `;
  let object = gql`${cadena}`;
  return object;
}


const GET_GENDERS = gql`
  {
    genders{
      list: nodes
    }
  }
`

const GET_PROFILES = gql`
  {
    profiles: profile{
      list: nodes
    }
  }
`

const GET_PLACES_BY_CITY_DIRECTORY = gql`
  query($city: Int){
    placeDirectories(
      condition: { cityId: $city }
    ){
      list: nodes{
        id
        name
        logo
        address
        cityId
        eventDirectories {
          nodes {
            name
            id
          }
        }
      }
    }
  }
`;


const GET_PLACES_BY_CITY = gql`
  query($city: Int){
    places(
      condition: { cityId: $city }
    ){
      list: nodes{
        id
        name
        logo
        address
      }
    }
  }
`;

const handleSearchEvents = (start, end, agencyId) => {
  let cadena = `
    {
      agency(id: "${agencyId}" ){
        id
        quotations 
        (orderBy: CODE_DESC
          ${start === '' && end === '' ? '' :
      `
              filter: {
                ${start !== '' ? `startDate: {greaterThanOrEqualTo: "${start}"}` : ''}
                ${end !== '' ? `endDate: {lessThanOrEqualTo: "${end}" }` : ''}
              }
            `
    })
          {
          nodes {
            events {
              nodes {
                id
                logo
                name
                startDate
                endDate
                state: stateId
                quotation{
                  code
                  createdAt
                }
                eventDirectory {
                  places:placeDirectory {
                    id
                    name
                    city {
                      id
                      name
                      country {
                        id 
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } 
  `
  // console.log("cadena: ", cadena)
  let object = gql`${cadena}`;
  return object;
}

const handlePlacesByCityNew = (city) => {
  let cadena = `
  {
    placeDirectories(condition: {cityId: ${city}}) {
      nodes {
        name
        id
        city {
          id
          name
        }
      }
    }
  }`
  let object = gql`${cadena}`;
  return object;
}

const GET_STATE_EVENT = gql`
  query MyQuery {
  stateItalentts(filter: { name: {startsWith: "Evento "}}) {
    nodes {
      id
      name
    }
  }
}
`
const GET_EVENT_ALL = gql`
  query($id: UUID! ){
    event(id: $id){
      id
      name
      description
      logo
      coverPage
      startDate
      endDate
      url
      totalDays
      totalEvent
      additionalProfiles
      stateId
      quotationId
      finalClientRelation {
        id
        name
        urlLogo
      }
      state{
        id
        name
      }
      quotation{
        priceAgentService
        code
        subtotalItalentt
        client {
          id
          name
          nameProducer
          address
          phone
          logo
          typeIdentificationId
          identification
          url
          emailProducer
          city {
            id
            name
            country {
              id
              name
            }
          }
        }
      }
      eventDirectory {
        places: placeDirectory{
          id
          name
          address
          logo
          city{
            id
            name
            country{
              id
              name
            }
          }            
        }
      }
      personal: eventPersonals{
        list: nodes{
          id
          startDate
          endDate
          quantity
          profile
          gender
          budget
        }
      }
      announcements: convocatories {
        list: nodes {
          id
          startDate
          endDate
          colourEyes
          colourHair
          height
          unitMeasure
          texture
          language
          skills
          academicTraining
          observations
          eventPersonal {
            profile
            budget
          }
          state{
            id
            name
          }
          convocateds              
          {
            list: nodes{
              id
              stateId
              aplicationTalentState
              state{
                id
                name
              }
              talent{
                id
                name
                lastname
                stateId
                photo
                gender
                phone
              }
              urlContract
            }
          }
          city{
            id
            country{
              id
            }
          }
          code
        }
      }
    }
  }
`;

const GET_ID_CONVOCATED = gql`
query($id: UUID!, $talentId: UUID! ){
  convocateds( condition: { convocatoryId: $id, talentId: $talentId }) {
    nodes {
      id
    }
  }
}
`
const GET_DATA_REQUIRED_CABIFY = gql`
  query($id: UUID! ){
    convocated(id: $id) {
      id
      talent {
        email
        lastname
        name
        phone
        address
        residenceCity {
          country {
            id
            name
          }
        }
      }
      convocatory {
        event {
          address
          name
          stateId
          cities {
            nodes {
              id
              name
              country {
                name
              }
            }
          }
          quotation {
            code
          }
        }
        code
      }
    }
  }
`

const GET_USER_TRANSPORTATION_BY_CITY = gql`
  query($id: UUID!, $city: Int ){
    plataformTransportation(id: $id) {
      fee
      niriunGain
      userTransportations(condition: {cityId: $city}) {
        nodes {
          id
          email
        }
      }
    }
  }
`

const GET_DATA_AGENCY_CONTRACT = gql`
  query($id: UUID! ){
    convocatory(id: $id) {
      code
      event {
        stateId
        name
        quotation{
          foodTransportation
          agency {
            address
            name
            identification
            city {
              name
              country {
                name
              }
            }
            users {
              nodes {
                email
                identification
                name
                lastname
                address
                city {
                  name
                }
              }
            }
            legalRepresentatives {
              nodes {
                name
                document
                city {
                  name
                country {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const GET_EVENT_BY_ID = gql`
  query($id: UUID! ){
    event(id: $id){
      name
      logo
      startDate
      endDate
      totalDays
      additionalProfiles
      stateId
      finalClientColumn
      finalClientRelation {
        id
        name
        urlLogo
      }
      state{
        id
        name
      }
      quotation{
        code
        agency{
          name
          logo
        }
      }
      eventDirectory {
        places: placeDirectory{
              name
              address
              city{
                name
                country{
                  name
                }
              }            
            }            
      }
      personal: eventPersonals{
        list: nodes{
          quantity
          profile
          gender
        }
      }
      announcements: convocatories {
        list: nodes {
          convocateds              
          {
            list: nodes{
              talent{
                id
                name
                lastname
                stateId
                photo
                gender
              }
              stateId
              aplicationTalentState
            }
          }
        }
      }
      quotation{
        client {
          name
          logo
        }
      }
    }
  }
`;

const GET_TALENTS = gql`
  query {
    talents {
      edges {
        node {
          id
          idFcm
          name
          lastname
          email
          birthdate
          hairColour
          eyeColour
          height
          weight
          generalDescription
          shortDescription
          gender
          phone
          identification
          address
          language
          twitter
          facebook
          instagram
          snapchat
          youtube
          spotify
          deezer
          photo
          unitMeasure
          bust
          waist
          hip
          shirt
          pants
          footwear
          statePhoto
          observation
          createAt
          state{
            id
            name
          }
          typeDocument {
            id
            name
          }
          residenceCity {
            id
            name
            country {
              id
              name
            }
          }
          profiles: profileTalents {
            list: nodes {
              id
              main
              profileUserTalentId
              profileUserTalent {
                profile
              }
            }
          }
          skillTalents {
            edges {
              node {
                id
                observations
                levelId
                level {
                  name
                }
                skill {
                  name
                  areaSkills {
                    nodes {
                      areaId
                      area {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
          images: multimedias{
            list: nodes{
              id
              route
              stateId
              type
              observation
            }
          }
          academicTrainings{
            list: nodes{
              id
              place
              description
            }
          }
          historicalExperience: historicalTalentExperiencies{
            list: nodes{
              id
              description
              startDate
              endDate
              area{
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_TALENTS_BY_FILTER = gql`
  # query($name: String, $lastname: String){
  query{
    talents(filter: {
      stateId: { equalTo: 18 }
      # stateId: { lessThanOrEqualTo: 19 }
      # name: { includesInsensitive: $name }
      # lastname: {includesInsensitive: $lastname}
    }){
      list: nodes{
        id
        name
        lastname
        gender
        photo
        profiles: profileTalents{
          list: nodes{
            id
            profileUserTalent {
              profile
            }
          }
        }
        residenceCityId
        city: residenceCity{
          id
          name
          country{
            id
            name
          }
        }
      }
    }
  }
`

const GET_UNIT_MEASURES = gql`
  {
    unitMeasures{
      list: nodes
    }
  }
`;

const GET_PARAM_CONTRACT = gql`
query {
  contracts {
    nodes {
      description
      order
      typeContract
      clause
    }
  }
}

`;

const GET_PROFILE_USER = gql`
  query($id: UUID!) {
    user(id: $id) {
      id
      name
      lastname
      phone
      email
      typeIdentificationId
      identification
      corporateEmail
      corporatePassword
      address
      city {
        id
        name
        country {
          id
          name
        }
      }
      agencies {
        list: nodes {
          id
          name
          logo
          url
          email
          phone
          legalNature
          address
          gain
          identification
          responsableIva
          personaNaturalArt383AEt
          declaranteRenta
          granContribuyente
          regimenSimple
          autoretenedorRenta
          autoretenedorIca
          typeIdentification {
            id
            name
          }
          city{
            id
            name
            country{
              id
              name
            }
          }
          legalRepresentatives {
            nodes {
              id
              document
              name
              city {
                id
                country {
                  id
                }
              }
            }
          }
          payment
        }
      }
      currency{
       id
      }
    }
  }
`;

const GET_EVENTS_BY_AGENCY_APPLY_AGENT = gql`
  query($id: UUID!){
    agency(id: $id){
      id
      quotations(orderBy: CODE_DESC){
        nodes {
          events {
            nodes {
              id
              name
              quotation {
                code
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;

const GET_DATA_FOR_APPLY_AGENCY = gql`
  query($id: UUID!) {
    user(id: $id){
      name
      lastname
      email
      agencies{
        list: nodes{
          id
          name
          legalNature
        }
      }
    }
  }
`;

const GET_TALENT = gql`
  query($id: UUID!) {
    talent(id: $id) {
      id
      name
      lastname
      email
      birthdate
      hairColour
      eyeColour
      height
      weight
      generalDescription
      shortDescription
      gender
      phone
      identification
      address
      language
      twitter
      facebook
      instagram
      snapchat
      youtube
      spotify
      deezer
      tiktok
      photo
      unitMeasure
      bust
      waist
      hip
      shirt
      pants
      footwear
      state {
        id
        name
      }
      typeDocument {
        id
        name
      }
      residenceCity {
        id
        name
        country {
          id
          name
        }
      }
      profiles: profileTalents {
        list: nodes {
          id
          main
          profileUserTalentId
          profileUserTalent {
            profile
          }
        }
      }
      skillTalents {
        edges {
          node {
            id
            observations
            levelId
            level {
              name
            }
            skill {
              name
              areaSkills {
                nodes {
                  areaId
                  area {
                    name
                  }
                }
              }
            }
          }
        }
      }
      images: multimedias{
        list: nodes{
          id
          route
          stateId
          type
        }
      }
      academicTrainings{
        list: nodes{
          id
          place
          description
        }
      }
      historicalExperience: historicalTalentExperiencies{
        list: nodes{
          id
          description
          startDate
          endDate
          area{
            id
            name
          }
        }
      }
    }
  }
`;

const GET_TALENT_CONTRACT = gql`
  query($id: UUID!) {
    talent(id: $id) {
      name
      lastname
      email
      phone
      identification
      address
      state {
        id
        name
      }
      typeDocument {
        id
        name
      }
      residenceCity {
        id
        name
        country {
          id
          name
        }
      }
    }
  }
`;

const GET_CLIENTS_QUOTE = gql`
  query($id: UUID!) {
    agency(id: $id) {
      id
      name
      clients(filter: { state: { distinctFrom: 53 } }) {
        list: nodes {
          id
          name
          state
          logo
          nameProducer
          emailProducer
          phoneProducer
        }
      }
    }
  }
`;
const GET_AGENCY_PRICE_TAXATION = gql`
  query($id: UUID!){
    agency(id: $id){
      agencyPricesTaxations {
        list: nodes {
          id
        }
      }
    }
  }
`

const GET_CODE_AGENCY = gql`
  query(
    $id: UUID!
    $code: String
  ) {
    quotations(condition: {code: $code, agencyId: $id}) {
      edges {
        node {
          id
        }
      }
    }
  }
`

const GET_TAXATION_AGENCY = gql`
  query($id: UUID!) {
    agency(id: $id) {
      id
      name
      gain
      cityId
      typeAgency
      consecutive: consecutiveQuote
      legalNature
      responsableIva
      personaNaturalArt383AEt
      declaranteRenta
      granContribuyente
      regimenSimple
      autoretenedorRenta
      autoretenedorIca
      taxations: agencyPricesTaxations {
        list: nodes {
          id
          price
          unity
          priceTaxationByCity: pricesTaxation {
            id
            price
            unity
            city {
              id
              name
            }
            taxation {
              id
              name
            }
          }
        }
      }
    }
  }
`

const GET_TAXATION_BY_REGISTER = gql`
  query($idCountry: Int!){
    taxations(condition: {
      countryId: $idCountry
    }){
      list: nodes{
        id
        name
        regionalEntity
        pricesTaxations{
          list: nodes{
            id
            cityId
            observations
          }
        }
      }
    }
  }
`;

const GET_QUOTES = gql`
  query($id: UUID){
    quotes: quotations(
      condition: { agencyId: $id }
      orderBy: CODE_ASC
      ){
      list: nodes{
        id
        nameClient
        nameClient
        nameContact
        emailClient
        phoneClient
        place
        nameEvent
        stateId
        code
        startDate
        endDate
        createdAt
        city{
          id
          country{
            id
          }
        }
        client{
          id
          name
          logo
          dialingCode
          phone
        }
        dialingCode
      }
    }
  }
`;

const GET_QUOTE_FOR_EDIT = gql`
  query($id: UUID!){
    quotation(id: $id){
      id
      nameClient
      nameContact
      emailClient
      place
      quantityHours
      priceGain
      percentageGain
      priceAgentService
      percentageAgentService
      clientId
      stateId
      client{
        id
        name
        nameProducer
        emailProducer
        phoneProducer
        dialingCode
      }
      city{
        id
        name
        country{
          id
          name
          code
        }
      }
      subtotalItalentt
      ivaItalentt
      totalItalentt
      subtotal
      iva
      total
      feeding
      transport
      nameEvent
      foodTransportation
      observation
      talents: quotationTypeTalents{
        list: nodes{
          id
          type: profile
          quantityDays
          budget
          paymentTalentProducer
          subtotalProducer
          ivaProducer
          totalProducer
          paymentTalentClient
          subtotalClient
          ivaClient
          totalClient
          paymentTalentRetention
          gainItalentt
          payPerDay
          announcement
          totalAnnouncement
          reteFuente
          reteIca
          reteIva
          expectedPayment
          italentt
          talentCosts
          serviceAgentCost
          foodCost
          transportationCost
          grossProfit
          incomeTax
          netIncome
          announcementAtt
          genders: typeTalentGenders{
            list: nodes{
              id
              gender
              quantity
            }
          }
        }
      }
      code
      startDate
      endDate
      dialingCode
      phoneClient
      currencyId
    }
  }
`;

const GET_IDFCM_TALENT = gql`
  query($city: Int!) {
    talents(
      filter: {
        residenceCityId: { equalTo: $city }
        idFcm: { isNull: false }
      }
    ) {
      list: nodes {
        idFcm
      }
    }
  }
`;

const GET_IDFCM_TALENT_BY_ID = gql`
  query($id: UUID!) {
    talents(
      filter: {
        id: { equalTo: $id} 
        idFcm: { isNull: false }
      }
    ) {
      list: nodes {
        idFcm
      }
    }
  }
`;

const GET_ALL_IDFCM_TALENT = gql`
  query{
    talents(
      filter: {
        idFcm: { isNull: false }
      }
    ) {
      list: nodes {
        idFcm
      }
    }
  }
`;

const GET_IDFCM_TALENT_EVENT = gql`
  query($id: UUID!){
    convocatory(id: $id){
      convocateds(condition: {stateId: 1813}) {
        edges {
          node {
            talent {
              idFcm
            }
          }
        }
      }
      event {
        name
        quotation {
          code
        }
      }
    }
  }
`;

const GET_DATA_GAIN = gql`
  query($id: UUID!) {
    agency(id: $id) {
      quotations{
        nodes{
          id
          createdAt
          events (condition: {stateId: 36}){
            list: nodes{
              startDate
              endDate
              quotation {
                total
                priceGain
              }
              eventDirectory {
                placeDirectory {
                  city {
                    name
                  }
                }
              }
              personal: eventPersonals{
                list: nodes{
                  quantity
                  profile
                  gender
                }
              }            
            }
          }
        }
      }
    }
  }
`;

const GET_AGENCY_BY_ID = gql` 
  query ($id: UUID!, $oneYearAgo: Datetime!) {
    agency(id: $id) {
      name
      legalNature
      email
      phone
      gain
      stateItalenttByState {
        name
        id
      }
      city {
        name
        country {
          name
        }
      }
      typeIdentification {
        name
      }
      address
      identification
        quotations(
          orderBy: CODE_DESC, 
          filter: {startDate: {greaterThanOrEqualTo: $oneYearAgo}}
        ){
        nodes {
          events {
            list: nodes {
              id
              logo
              name
              startDate
              endDate
              state: stateId
              quotation {
                code
                createdAt
              }
              eventDirectory {
                places: placeDirectory {
                  id
                  name
                  city {
                    name
                    id
                    country {
                      id
                      name
                    }
                  }
                }
              }
              urlAdvance
              validAdvance
            }
          }
        }
      }
    }
  }
`;

const GET_AGENCY_LIST = gql`
  query getAgencyList {
  agencies {
    nodes {
      id
      name
      legalNature
      logo
      email
      phone
      gain
      stateItalenttByState {
        id
        name
      }
      city {
        name
      }
      users {
        nodes {
          id
        }
      }
    }
  }
}
`
const GET_CONSTANT = gql`
  query getConstant {
  stateItalentts(filter: { id: { in: [71, 72, 73, 74, 75, 21, 22, 23, 24, 26] } }) {
    nodes {
      id
      name
    }
  }
}
`
const GET_CONSTANT_COBRO = gql`
query getConstant {
  constants(filter: {id: {greaterThanOrEqualTo: 70, lessThanOrEqualTo: 79}}) {
    edges {
      node {
        id
        name
        description
      }
    }
  }
}
`

const GET_CURRENCY = gql`
query getCurrency {
  currencies {
    edges {
      node {
        id
        code
        name
      }
    }
  }
}
`

const GET_CURRENCY_USER = gql`
  query($id: UUID!) {
    user(id: $id) {
      currency{
       id
      }
    }
  }
`

export {
  getDataUserxEmail,
  getParameters,
  getTypesIdentification,
  getUserLogin,
  getAdminLogin,
  getEmailInActivateAccount,
  getEmailForgotPassword,
  getEmailForgotPasswordTalent,
  GET_EVENTS_CLIENTS_BY_AGENCY,
  getStatesAgencyUser,
  getProfilesTalent,
  getStatesAll,
  GET_CLIENTS_BY_AGENCY,
  getServices,
  GETAGENCYBYUSER,
  GET_EVENTS_BY_AGENCY,
  GET_CLIENTS_BY_AGENCY_SELECT,
  GET_USER_TRANSPORTATION_BY_CITY,
  GET_EVENTS_DIRECTORY,
  GET_GENDERS,
  GET_PROFILES,
  GET_PLACES_BY_CITY_DIRECTORY,
  GET_PLACES_BY_CITY,
  handleSearchEvents,
  handlePlacesByCityNew,
  GET_EVENT_ALL,
  GET_STATE_EVENT,
  GET_ID_CONVOCATED,
  GET_DATA_AGENCY_CONTRACT,
  GET_DATA_REQUIRED_CABIFY,
  GET_EVENT_BY_ID,
  GET_TALENTS,
  GET_TALENTS_BY_FILTER,
  GET_UNIT_MEASURES,
  GET_PARAM_CONTRACT,
  GET_PROFILE_USER,
  GET_EVENTS_BY_AGENCY_APPLY_AGENT,
  GET_DATA_FOR_APPLY_AGENCY,
  GET_TALENT,
  GET_TALENT_CONTRACT,
  GET_CLIENTS_QUOTE,
  GET_TAXATION_BY_REGISTER,
  GET_QUOTES,
  GET_QUOTE_FOR_EDIT,
  GET_CODE_AGENCY,
  GET_TAXATION_AGENCY,
  GET_IDFCM_TALENT,
  GET_IDFCM_TALENT_BY_ID,
  GET_ALL_IDFCM_TALENT,
  GET_IDFCM_TALENT_EVENT,
  GET_DATA_GAIN,
  GET_AGENCY_PRICE_TAXATION,
  GET_AGENCY_LIST,
  GET_AGENCY_BY_ID,
  GET_CONSTANT,
  GET_CONSTANT_COBRO,
  GET_CURRENCY,
  GET_CURRENCY_USER
};