import React, { Fragment, useState, useRef, forwardRef } from 'react';
import { Col, Row, Button, Label, Input, InputGroup, InputGroupAddon, InputGroupText, CustomInput } from 'reactstrap';
import DefaultFooter from "../../../containers/DefaultLayout/DefaultFooter";
import { AppFooter, AppHeader } from '@coreui/react';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import './Contract.css';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';

const LoadingSpinner = () => {
    const override = css`
        display: block;
        margin: 0 auto;
        color: #20a8d8;
        size: 100;
    `;

    return (
        <ClipLoader loading={true} css={override} />
    );
};

const ContractView = forwardRef((props, ref) => {
    const {
        loading,
        eventValid,
        contractEvent,
        contractPhoto,
        habeasData,
        initialValues,
        submit,
        documentation,
        message,
    } = props;

    const [switchStates, setSwitchStates] = useState(initialValues.clausula);

    const handleCheck = (switchName, setFieldValue) => {
        const newSwitchStates = {
            ...switchStates,
            [switchName]: { ...switchStates[switchName], value: !switchStates[switchName].value },
        };

        Object.keys(newSwitchStates).forEach((fieldName) => {
            setFieldValue(`clausula.${fieldName}.value`, newSwitchStates[fieldName].value);
        });

        setSwitchStates(newSwitchStates);
    };

    const [switchHabeas, setSwitchHabeas] = useState(initialValues.habeasData);

    const handleHabeas = (switchName, setFieldValue) => {
        const newSwitchStates = {
            ...switchHabeas,
            [switchName]: { ...switchHabeas[switchName], value: !switchHabeas[switchName].value },
        };

        Object.keys(newSwitchStates).forEach((fieldName) => {
            setFieldValue(`habeasData.${fieldName}.value`, newSwitchStates[fieldName].value);
        });

        setSwitchHabeas(newSwitchStates);
    };

    const [base64Files, setBase64Files] = useState({});

    const handleFileChange = (event, docName, setFieldValue) => {
        const file = event.currentTarget.files[0];
        if (file) {
            convertToBase64(file, docName, setFieldValue);
        }
    };

    const convertToBase64 = (file, docName, setFieldValue) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1];
            const extension = file.type.split('/')[1]; // Obtiene la extensión del archivo
            // console.log(`Document: ${docName}, Base64: ${base64String}, Extension: ${extension}`);
            setBase64Files(prevState => ({ ...prevState, [docName]: { file: base64String, extension } }));
            setFieldValue(docName, { file: base64String, extension, type: docName });
        };
        reader.onerror = (error) => {
            console.error("Error converting file to base64: ", error);
        };
    };

    return (
        <Fragment>
            <div className="app">
                <AppHeader style={{ display: "flex", justifyContent: "center" }}>
                    <Row style={{ width: "100%" }}>
                        <Col md={12}>
                            <p style={{ textAlign: "center", fontSize: "18px" }}>
                                <strong>
                                    Contrato Online de Prestación de Servicios para Personal
                                </strong>
                            </p>
                        </Col>
                    </Row>
                </AppHeader>

                {
                    loading ?
                        <LoadingSpinner />
                        :
                        <div ref={ref}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={Yup.object().shape({
                                    typeContract: Yup.string().required('Por favor seleccione una opción'),
                                    name: Yup.string().required('Por favor llene este campo en la app Niriun'),
                                    identification: Yup.string().required('Por favor llene este campo en la app Niriun'),
                                    phone: Yup.string().required('Por favor llene este campo en la app Niriun'),
                                    email: Yup.string().required('Por favor llene este campo en la app Niriun'),
                                    address: Yup.string().required('Por favor llene este campo en la app Niriun'),
                                    habeasData: Yup.object().shape({
                                        habeasData: Yup.object().shape({
                                            value: Yup.bool().oneOf([true], 'Se deben aceptar los términos')
                                        })
                                    }),
                                    clausula: Yup.object().shape({
                                        clausula: Yup.object().shape({
                                            value: Yup.bool().oneOf([true], 'Se deben aceptar los términos')
                                        })
                                    }),
                                    docId: Yup.mixed().required('Por favor suba un documento'),
                                    // .test('fileFormat', 'Formato no soportado', value => !value || (value && ['application/pdf', 'image/png', 'image/jpeg'].includes(value.type))),
                                    rut: Yup.mixed().required('Por favor suba un documento'),
                                    // .test('fileFormat', 'Formato no soportado', value => !value || (value && ['application/pdf', 'image/png', 'image/jpeg'].includes(value.type))),
                                    eps: Yup.mixed().required('Por favor suba un documento'),
                                    // .test('fileFormat', 'Formato no soportado', value => !value || (value && ['application/pdf', 'image/png', 'image/jpeg'].includes(value.type))),
                                    arl: Yup.mixed().required('Por favor suba un documento'),
                                    // .test('fileFormat', 'Formato no soportado', value => !value || (value && ['application/pdf', 'image/png', 'image/jpeg'].includes(value.type))),
                                })}
                                onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                                    try {
                                        await submit(values);
                                    } catch (error) {
                                        console.log(error);
                                    }
                                    setSubmitting(false);
                                }}
                            >
                                {({ values, isSubmitting, handleSubmit, handleChange, handleBlur, setFieldValue, dirty }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Row className='margin-ajust app-body-show' style={{ display: "flex", justifyContent: "center" }}>
                                            {
                                                eventValid ?
                                                    <Row className="event-info-container">
                                                        <Col md={12} className='i-cards i-padding'>
                                                            <Row>
                                                                <Col sm={12} md={6} xl={4} >
                                                                    <Label><strong>Seleccione el tipo de contrato.</strong> </Label>
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="icon-notebook"></i>
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type="select"
                                                                            name="typeContract"
                                                                            value={values.typeContract}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                        >
                                                                            <option value="">Seleccione un Tipo de Contrato</option>
                                                                            <option value="eventos">Sin derechos de uso de imagen</option>
                                                                            <option value="fotografia">Con derechos de uso de imagen</option>
                                                                        </Input>
                                                                    </InputGroup>
                                                                    <ErrorMessage name="typeContract">
                                                                        {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                    </ErrorMessage>
                                                                </Col>

                                                                <Col sm={12} md={6} xl={4}>
                                                                    <Label>Fecha del contrato:</Label>
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="icon-user"></i>
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Field
                                                                            type="text"
                                                                            name="dateContract"
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    {...field}
                                                                                    autoComplete="off"
                                                                                    disabled
                                                                                />
                                                                            )}
                                                                        />
                                                                    </InputGroup>
                                                                    <ErrorMessage name="dateContract">
                                                                        {msg => <div className="delay-2s field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                    </ErrorMessage>
                                                                </Col>

                                                                {
                                                                    values.typeContract == "eventos" ?
                                                                        <Col md={12} style={{ marginTop: "1rem", color: "red" }}>
                                                                            <p style={{ fontSize: "14px", textAlign: "start" }}>
                                                                                <strong>NOTA: </strong>Asistirá a un evento corporativo para apoyar las labores requeridas durante el desarrollo del mismo.
                                                                            </p>
                                                                        </Col>
                                                                        :
                                                                        <div style={{ display: "none" }}></div>
                                                                }

                                                                {
                                                                    values.typeContract == "fotografia" ?
                                                                        <Col md={12} style={{ marginTop: "1rem", color: "red" }}>
                                                                            <p style={{ fontSize: "14px", textAlign: "start" }}>
                                                                                <strong>NOTA: </strong>Asistirá a un evento o sesión fotos o videos para la creación de contenido para página web, redes sociales o campañas para medios internos o externos.
                                                                            </p>
                                                                        </Col>
                                                                        :
                                                                        <div style={{ display: "none" }}></div>
                                                                }

                                                            </Row>
                                                        </Col>

                                                        <Fragment>
                                                            <Row className='i-cards'>
                                                                <Col md={12}>
                                                                    <strong>
                                                                        <p style={{ fontSize: "16px" }}>
                                                                            {habeasData.clause}:
                                                                        </p>
                                                                    </strong>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <p style={{ fontSize: "14px", textAlign: "start" }}>
                                                                        {habeasData.description}
                                                                    </p>
                                                                </Col>

                                                                {Object.keys(switchHabeas).map((switchName) => (
                                                                    <Col sm={12} md={6} xl={4} className="mt-2" key={switchName}>
                                                                        <CustomInput
                                                                            style={{ fontSize: "14px" }}
                                                                            type="switch"
                                                                            id={switchName}
                                                                            name={`habeasData.${switchName}.value`}
                                                                            label={switchHabeas[switchName].label}
                                                                            checked={switchHabeas[switchName].value}
                                                                            onChange={() => {
                                                                                handleHabeas(switchName, setFieldValue);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`habeasData.${switchName}.value`}>
                                                                            {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                        </ErrorMessage>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Fragment>

                                                        {/* Clausulas del contrato */}
                                                        {values.typeContract == "eventos" ?
                                                            contractEvent
                                                                .slice()
                                                                .sort((a, b) => a.order - b.order)
                                                                .map((data, index) => (
                                                                    <Fragment key={index}>
                                                                        <Row className='i-cards'>
                                                                            <Col md={12}>
                                                                                <strong>
                                                                                    <p style={{ fontSize: "16px" }}>
                                                                                        {data.clause}:
                                                                                    </p>
                                                                                </strong>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <p style={{ fontSize: "14px", textAlign: "start" }} dangerouslySetInnerHTML={{ __html: data.description }} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Fragment>
                                                                ))
                                                            :
                                                            <div></div>
                                                        }

                                                        {values.typeContract == "fotografia" ?
                                                            contractPhoto
                                                                .slice()
                                                                .sort((a, b) => a.order - b.order)
                                                                .map((data, index) => (
                                                                    <Fragment key={index}>
                                                                        <Row className='i-cards'>
                                                                            <Col md={12}>
                                                                                <strong>
                                                                                    <p style={{ fontSize: "16px" }}>
                                                                                        {data.clause}:
                                                                                    </p>
                                                                                </strong>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <p style={{ fontSize: "14px", textAlign: "start" }} dangerouslySetInnerHTML={{ __html: data.description }} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Fragment>
                                                                ))
                                                            :
                                                            <div></div>
                                                        }

                                                        <Col md={12} className='i-cards'>
                                                            {Object.keys(switchStates).map((switchName) => (
                                                                <Col sm={12} md={6} xl={4} className="mt-2" key={switchName}>
                                                                    <CustomInput
                                                                        style={{ fontSize: "14px" }}
                                                                        type="switch"
                                                                        id={switchName}
                                                                        name={`clausula.${switchName}.value`}
                                                                        label={switchStates[switchName].label}
                                                                        checked={switchStates[switchName].value}
                                                                        onChange={() => {
                                                                            handleCheck(switchName, setFieldValue);
                                                                        }}
                                                                    />
                                                                    <ErrorMessage name={`clausula.${switchName}.value`}>
                                                                        {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                    </ErrorMessage>
                                                                </Col>
                                                            ))}
                                                        </Col>

                                                        <Col md={12} className='i-cards'>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <p style={{ fontSize: "16px" }}>
                                                                        <strong>Cláusula sexta: </strong> Datos del contratista.
                                                                    </p>
                                                                </Col>

                                                                <Col sm={12} md={6} xl={4}>
                                                                    <Label> <strong>Nombre Completo: </strong>{values.name}</Label>
                                                                    <ErrorMessage name="name">
                                                                        {msg => <div className="delay-2s field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                    </ErrorMessage>
                                                                </Col>

                                                                <Col sm={12} md={6} xl={4} className="mt-2">
                                                                    <Label><strong>Cédula: </strong>{values.identification}</Label>
                                                                    <ErrorMessage name="identification">
                                                                        {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                    </ErrorMessage>
                                                                </Col>

                                                                <Col sm={12} md={6} xl={4} className="mt-2">
                                                                    <Label><strong>Número de Celular: </strong>{values.phone}</Label>
                                                                    <ErrorMessage name="phone">
                                                                        {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                    </ErrorMessage>
                                                                </Col>

                                                                <Col sm={12} md={6} xl={4} className="mt-2">
                                                                    <Label><strong>Dirección: </strong>{values.address}</Label>
                                                                    <ErrorMessage name="address">
                                                                        {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                    </ErrorMessage>
                                                                </Col>

                                                                <Col sm={12} md={6} xl={4} className="mt-2">
                                                                    <Label><strong>Email: </strong>{values.email}</Label>
                                                                    <ErrorMessage name="email">
                                                                        {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                    </ErrorMessage>
                                                                </Col>

                                                                <Col md={12}>
                                                                    <p style={{ fontSize: "16px", marginTop: '1rem' }}>
                                                                        <strong>NOTA: </strong> Para editar los datos del contratista, debe hacerlo desde la aplicación móvil, en la sección del Perfil.
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={12} className='i-cards'>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <p style={{ fontSize: "16px" }}>
                                                                        <strong>Documentos</strong>
                                                                    </p>
                                                                </Col>
                                                                {documentation.map((doc, index) => (
                                                                    <Col md={6} key={index} className="mt-2">
                                                                        <label> <strong>{doc.label}</strong> </label>
                                                                        <br />
                                                                        <input
                                                                            type="file"
                                                                            accept=".pdf,.png,.jpg"
                                                                            // onChange={(event) => handleFileChange(event, doc.name, setFieldValue)}
                                                                            onChange={(event) => handleFileChange(event, doc.name, setFieldValue)}
                                                                        />
                                                                        <ErrorMessage name={doc.name} component="div" style={{ color: 'red' }} />
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Col>

                                                        <Col md={12} className='i-cards'>
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                disabled={!dirty || isSubmitting}
                                                                style={{ cursor: !dirty || isSubmitting ? 'not-allowed' : 'pointer' }} >
                                                                <i className="fa fa-save"></i> &nbsp;
                                                                {isSubmitting ? 'Cargando...' : 'Firmar'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Row className="justify-content-center" style={{ marginTop: "16rem" }}>
                                                        <Col md="8">
                                                            <div>
                                                                <h1 className="float-left display-3 mr-4">404</h1>
                                                                <h4 className="pt-3">{message}</h4>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                            }
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                }

                <AppFooter className="app-footer">
                    <DefaultFooter />
                </AppFooter>
            </div>
        </Fragment>
    );
});

export default ContractView;